<template>
  <div>
    <ac-module-heading> Transaction Rules </ac-module-heading>
    <v-card class="card-wrapper">
      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <div class="d-flex align-center pb-5">
          <ac-select
            label="Company"
            v-model="selectedCompany"
            :items="companies"
            class="company-select me-3"
          />
          <ac-button title="Add Entry" :icon="icons.mdiPlus" />
        </div>
        <v-spacer />
        <div class="d-flex align-center pb-5">
          <ac-input
            v-model="searchQuery"
            placeholder="Search"
            outlined
            class="staff-list-search me-3"
          />
        </div>
      </v-card-text>
      <v-data-table
        :headers="headers"
        :items="transactionRules"
        :options.sync="options"
        :server-items-length="totalTransactionRules"
        :loading="loading"
        class="text-no-wrap"
      >
        <template #[`item.date-created`]="{ item: { createdAt } }">
          <span>{{ formateDate(createdAt) }}</span>
        </template>

        <template #[`item.effective-date`]="{ item: { effectiveDate } }">
          <span>{{ formateDate(effectiveDate, 'DD MMM YYYY') }}</span>
        </template>

        <template #[`item.attachment`]="{ item: { attachment } }">
          <ac-icon-btn :text="attachment" :icon="icons.mdiFileDownloadOutline" icon-size="24" />
        </template>

        <template #[`item.actions`]>
          <div class="d-flex align-center justify-center">
            <ac-icon-btn text="Edit" color="primary" btn-class="me-1" :icon="icons.mdiPencil" />
            <ac-icon-btn text="View Logs" btn-class="me-1" color="success" :icon="icons.mdiEye" />
            <ac-icon-btn text="Delete" color="error" :icon="icons.mdiDeleteOutline" />
          </div>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import AcButton from '@/components/AcButton.vue'
import AcInput from '@/components/AcInput.vue'
import AcSelect from '@/components/AcSelect.vue'
import AcIconBtn from '@/components/AcIconBtn.vue'
import store from '@/store'
import padealingStoreModule from '@/store/modules/pa-dealing'
import { mdiDeleteOutline, mdiEye, mdiFileDownloadOutline, mdiPencil, mdiPlus } from '@mdi/js'
import { onUnmounted } from '@vue/composition-api'
import useTransactionRules from './composition-apis/transaction-rules'

export default {
  components: {
    AcButton,
    AcSelect,
    AcInput,
    AcIconBtn,
  },
  setup() {
    const PA_DEALING_STORE_MODULE_NAME = 'pa-dealing'

    // Register module
    if (!store.hasModule(PA_DEALING_STORE_MODULE_NAME)) {
      store.registerModule(PA_DEALING_STORE_MODULE_NAME, padealingStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PA_DEALING_STORE_MODULE_NAME))
        store.unregisterModule(PA_DEALING_STORE_MODULE_NAME)
    })

    const {
      headers,
      options,
      transactionRules,
      totalTransactionRules,
      searchQuery,
      companies,
      selectedCompany,
      loading,
      formateDate,
    } = useTransactionRules()

    return {
      headers,
      options,
      transactionRules,
      totalTransactionRules,
      searchQuery,
      companies,
      selectedCompany,
      loading,
      formateDate,
      icons: { mdiDeleteOutline, mdiEye, mdiFileDownloadOutline, mdiPencil, mdiPlus },
    }
  },
}
</script>

<style lang="scss" scoped>
.card-wrapper {
  .company-select {
    max-width: 16rem;
  }
  .staff-list-search {
    max-width: 12rem;
  }
}
</style>

import store from '@/store'
import { ref, watch } from '@vue/composition-api'
import moment from 'moment-timezone'

export default function useTransactionRules() {
  const transactionRules = ref([])
  const totalTransactionRules = ref(0)
  const loading = ref(false)
  const searchQuery = ref('')
  const isDialogVisible = ref(false)
  const companies = ref(['Company 1', 'Company 2', 'Company 3'])
  const selectedCompany = ref('')

  const headers = [
    { text: 'Date Created', value: 'date-created' },
    { text: 'Effective Date', value: 'effective-date' },
    { text: 'Transaction Type', value: 'type' },
    { text: 'Summary', value: 'summary' },
    { text: 'Attachment', value: 'attachment', sortable: false },
    { text: 'Status', value: 'status' },
    {
      text: 'Actions',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ]

  const options = ref({
    sortBy: ['id'],
    sortDesc: [true],
  })

  const fetchTransactionRules = () => {
    store
      .dispatch('pa-dealing/fetchTransactionRules', {
        q: searchQuery.value,
        options: options.value,
      })
      .then(data => {
        transactionRules.value = data
        totalTransactionRules.value = data.length

        loading.value = false
      })
      .catch(error => {
        console.log(error)
      })
  }

  watch([searchQuery, options], () => {
    loading.value = true
    fetchTransactionRules()
  })

  // *====================---*
  // *------- UI -------------------*
  // *====================---*

  const formateDate = (date, dateFormate) => {
    if (!date) return null
    return moment(date).format(dateFormate || 'DD MMM YYYY HH:MM:SS')
  }

  return {
    headers,
    options,
    transactionRules,
    totalTransactionRules,
    isDialogVisible,
    companies,
    selectedCompany,
    searchQuery,
    loading,
    formateDate,
  }
}
